<template>
    <div class="class-manage">
        <table-page-template
            v-bind="templateConfig"
            :paging-config.sync="getClassListParams"
            :total-count="totalCount"
            @page-change="getClassList"
        >
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="allotBtn(item)">分配课程</el-link>
                <el-link type="primary" :underline="false" @click="editClass(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="delClass(item)">删除</el-link>
            </template>
        </table-page-template>
        <my-dialog
            class="operate-class"
            :title="operateDialogTitle"
            :is-show.sync="operateDialog"
            :confirm-handler="confirmOperate"
            @closeDialog="clearFormData"
            padding="40px 0 40px"
        >
            <div class="form-box">
                <pack-el-form
                    ref="elForm"
                    :rules="rules"
                    :params.sync="operateParams"
                    :form-config="formConfig"
                    label-width="65"
                ></pack-el-form>
            </div>
        </my-dialog>
        <my-dialog class="delete-class" :title="`删除班级`" :is-show.sync="deleteDialog" :confirm-handler="confirmDelete">
            <div class="content">是否删除此班级, 删除后将无法恢复</div>
        </my-dialog>

      <el-dialog title="分配课程" :visible.sync="dialogFormVisible" width="615" @close="resetForm">
        <el-checkbox-group v-model="checkList">
          <el-checkbox :label="item.id"
                       style="margin-bottom: 24px; color: #000"
                       v-for="(item, index) in courseList"
                       :key="`courseList_${index}`">{{item.text}}</el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetForm">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";
import PackElForm from "components/common/PackElForm";
import MyDialog from "components/common/MyDialog";
import { operateClass, getClassListData, deleteClass } from "@/utils/apis";
export default {
    name: "Index",
    data() {
        return {
            tableData: [],
            getClassListParams: {
                paging: 1,
                page: 1,
                pageSize: 10,
            },
            operateParams: {
                banji_name: "",
                banji_id: "",
            },
            rules: {
                banji_name: [
                    { required: true, message: "请输入班级名称", trigger: "blur" },
                    { min: 2, message: "班级名称的长度不能小于2", trigger: "blur" },
                ],
            },
            totalCount: 1,
            deleteId: -1,
            //dialog
            deleteDialog: false,
            operateDialog: false,

          dialogFormVisible: false,
          checkList: [],
          currentIndex: null,
          courseList: [
            {
              id: 1,
              text: '商品类短视频脚本'
            },
            {
              id: 2,
              text: '美食类短视频拍摄与营运'
            },
            {
              id: 3,
              text: '鬼畜类短视频拍摄与营运'
            },
            {
              id: 4,
              text: '风景类短视频拍摄与营运'
            },
            {
              id: 5,
              text: '搞笑类短视频拍摄与营运'
            },
            {
              id: 6,
              text: '音乐类短视频拍摄与营运'
            },
          ]
        };
    },
    computed: {
        operateDialogTitle() {
            return this.operateParams.banji_id ? "编辑班级" : "创建班级";
        },
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: `创建班级`,
                    handler: this.createClass,
                },
                tableConfig: [
                    { label: `班级名称`, prop: "banji_name" },
                    { label: "班级人数", prop: "student_num", align: "center" },
                    { label: "创建时间", prop: "create_time", align: "center" },
                    { label: "操作", width: 300, align: "center" },
                ],
            };
        },
        formConfig() {
            return [
                {
                    label: "班级名称",
                    prop: "banji_name",
                    placeholder: "2-18个字符",
                    max: 18,
                },
            ];
        },
    },
    components: {
        TablePageTemplate,
        MyDialog,
        PackElForm,
    },
    methods: {
        init() {
            this.getClassList();
        },
        async getClassList() {
            const res = await getClassListData(this.getClassListParams);
            this.tableData = res.data.list;
            this.totalCount = res.data.total;
        },
        createClass() {
            this.operateDialog = true;
        },
        editClass(item) {
            Object.assign(this.operateParams, item);
            this.operateDialog = true;
        },
        async confirmOperate() {
            this.$refs.elForm.validate(async () => {
                let res = await operateClass(this.operateParams);
                this.$message.success(res.msg);
                this.operateDialog = false;
                this.getClassList();
            });
        },
        delClass(item) {
            this.deleteId = item.banji_id;
            this.deleteDialog = true;
        },
        async confirmDelete() {
            let res = await deleteClass({ banji_id: this.deleteId });
            this.$message.success(res.msg);

            this.getClassList();
            this.deleteDialog = false;
        },
        clearFormData() {
            this.operateParams.banji_name = "";
            this.operateParams.id = "";
        },

      allotBtn(val) {
        this.checkList = val.checkList || []
        this.dialogFormVisible = true
        this.currentIndex = this.tableData.findIndex(item => item.banji_id === val.banji_id)
      },
      resetForm() {
        this.dialogFormVisible = false
        this.checkList = []
      },
      submitForm() {
        if (this.checkList.length === 0) {
          return this.$message.warning('请选择课程')
        }
        this.tableData[this.currentIndex].checkList = this.checkList
        this.$message.success('分配成功')
        this.resetForm()
      }
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.class-manage {
    box-sizing: border-box;
    height: 100%;
    padding: 30px 30px 0;
}
</style>
